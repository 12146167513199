/*=============================================================================================	
    Company    : PT Web Architect Technology - webarq.com
    Document   : Stylesheet
    Author     : Iqbal Wahyudi Pratama - iqbalwahyudip.com
==============================================================================================*/
@import "sass-lib";

body { 
    color: #111111;
    font-size: 12px;
    font-family: "Lato"; 
    position: relative;
    overflow-x: hidden; 
}
img{ border:0; max-width:100%; height:auto; }
figure{margin: 0;}
.italic { font-style: italic; } 
.bold { font-weight: bold; }
.regular { font-weight: lighter; }
.fl { float: left; }
.fr { float: right; }
.clear { clear: both; }
p {
    margin: 1px 0 15px;
    line-height: 20px;
}
input, textarea, select {
    font-size: 12px;
    border: none;
    border: 1px solid #D8D8D8;
    padding: 10px;
    margin: 0;
    color: #666;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
}
input[type=checkbox], input[type=radio] {
    border-top-style: none;
    border-right-style: none;
    border-bottom-style: none;
    border-left-style: none;
}
h1 { font-size: 55px; font-family: "Trirong"; }
h2 { font-size: 42px; font-family: "Trirong"; }
h3 { font-size: 36px; font-family: "Trirong"; }
h4 { font-size: 20px; font-family: "Trirong"; } 
h5 { font-size: 16px; font-family: "Trirong"; } 
h6 { font-size: 14px; font-family: "Trirong"; }
/* structure 
----------------------------------------------------------------------------------------------*/
section { position: relative; }
.wrapper {width: 1170px;margin: 0 auto; @include boxSizing(border-box);}
.after_clear:after,.wrapper:after,section:after{clear:both;display: block;content:" "}
.before_clear:before{clear:both;display: block;content:" "}
.a-left{text-align: left}
.a-right{text-align: right}
.a-center{text-align: center}
.fullfloat{width: 100%; float: left;}
.fullinline{width: 100%; display: inline-block;}
.clr-pink{color: $pink}
.subtitle{font-size: 30px; line-height: 48px; font-weight: 400;}
.lineh{width: 140px; height: 2px; background: $pink; display: inline-block; margin: 15px 0;}
.tdate{
    position: relative;
    input[type="text"]{padding-right: 60px;}
    .ic-cus{position: absolute; top: 10px; right: 10px;}
}
/* global
----------------------------------------------------------------------------------------------*/

.btn-oval{
    border: 1px solid #f0001f; background: #f0001f; height: 50px; padding: 0 55px; line-height: 50px; color: #fff; font-family: "Trirong"; font-weight: 700; cursor: pointer; @include boxSizing(border-box);
    @include borderRadius(50px); @include transition(0.2s all);
    &:hover{border-color: #f0001f; background: #fff; color: #f0001f;}
    &.btn-loadmore{
        border-color: #eb4e5e; background: #eb4e5e; color: #fff; font-weight: 700; font-size: 18px; padding: 0 43px;
        &:hover{border-color: #eb4e5e; background: #fff; color: #eb4e5e;}
    }
    &.btn-pink{
        font-size: 18px; color: #fff; background: $pink; border-color: $pink;
        &:hover{border-color: $pink; background: #fff; color: $pink;}
    }
    &.btn-pink2{
        font-size: 18px; color: #fff; background: #eb4e5e; border-color: #eb4e5e;
        &:hover{border-color: #eb4e5e; background: #fff; color: #eb4e5e;}
    }
    &.btn-grclasy{
        font-size: 18px; color: #fff; background: #5a6d5a; padding-left: 12px; padding-right: 12px; border-color: #5a6d5a;
        &:hover{border-color: #5a6d5a; background: #fff; color: #5a6d5a;}
    }
    &.btn-inqury{
        &:hover{
            .ic-inquiry{background-position: bottom center;}
        }
    }
}
.link-line{
    font-size: 14px; color: $pink; position: relative; padding-left: 28px; font-family: "Trirong"; font-weight: 700; @include transition(0.2s all);
    &:before{content: ""; position: absolute; top: 12px; left: 0; width: 18px; height: 2px; background: $pink; @include transition(0.2s all);}
    &:hover{
        &:before{width: 25px;}
    }
}


/* icons icwp 
-----------------------------------------*/
.icwp{
    width: 20px; height: 20px; background-repeat: no-repeat; background-position: top center; display: inline-block; position: relative;
    /*&:hover{background-position: bottom center;}*/
    &.ic-fb, &.ic-ig, &.ic-tw, &.ic-yt, &.ic-gplus{
        width: 28px; height: 22px; @include transition(0.2s all);
        &:hover{background-position: bottom center;}
    }
    &.ic-fb{background-image: url('../images/material/ic-fb.png');}
    &.ic-ig{background-image: url('../images/material/ic-ig.png');}
    &.ic-tw{background-image: url('../images/material/ic-tw.png');}
    &.ic-yt{background-image: url('../images/material/ic-yt.png');}
    &.ic-gplus{background-image: url('../images/material/ic-gplus.png');}

    &.ic-fb-float, &.ic-ig-float, &.ic-tw-float, &.ic-gplus-float{
        width: 28px; height: 22px; @include transition(0.2s all);
        &:hover{background-position: bottom center;}
    }
    &.ic-fb-float{background-image: url('../images/material/ic-fb-float.png');}
    &.ic-ig-float{background-image: url('../images/material/ic-ig-float.png');}
    &.ic-tw-float{background-image: url('../images/material/ic-tw-float.png');}
    &.ic-gplus-float{background-image: url('../images/material/ic-gplus-float.png');}

    &.ic-fb-lg, &.ic-ig-lg, &.ic-tw-lg, &.ic-gplus-lg{
        width: 47px; height: 35px; @include transition(0.2s all);
        &:hover{background-position: bottom center;}
    }
    &.ic-fb-lg{background-image: url('../images/material/ic-fb-lg.png');}
    &.ic-ig-lg{background-image: url('../images/material/ic-ig-lg.png');}
    &.ic-tw-lg{background-image: url('../images/material/ic-tw-lg.png');}
    &.ic-gplus-lg{background-image: url('../images/material/ic-gplus-lg.png');}

    &.ic-mouse{background-image: url('../images/material/ic-mouse.png'); width: 20px; height: 30px;}
    &.ic-quotes{background-image: url('../images/material/ic-quotes.png'); width: 39px; height: 34px;}
    &.ic-video{background-image: url('../images/material/ic-video.png'); width: 23px; height: 14px;}
    &.ic-close{background-image: url('../images/material/ic-close.png'); width: 16px; height: 13px;}
    &.ic-date{background-image: url('../images/material/ic-date.png'); width: 34px; height: 34px;}
    &.ic-inquiry{background-image: url('../images/material/ic-inquiry.png'); width: 30px; height: 30px; @include transition(0.2s all);}

    &.ic-phone,&.ic-print, &.ic-mark{width: 19px; height: 18px;position: relative; top: 3px; margin-right: 8px;}
    &.ic-phone{background-image: url('../images/material/ic-phone.png');}
    &.ic-print{background-image: url('../images/material/ic-print.png');}
    &.ic-mark{background-image: url('../images/material/ic-mark.png');}
    &.ic-wa{background-image: url('../images/material/ic-wa.png'); width: 30px; height: 30px;@include transition(0.2s all);}
    
}
/* end icons icwp 
-----------------------------------------*/


/* header
----------------------------------------------------------------------------------------------*/
header{
    @include boxSizing(border-box); padding: 0 50px; position: absolute; top: 0; left: 0; right: 0; z-index: 1100; @include transition(0.3s background);
    .logo, .logo-float{float: left; padding-top: 10px;}
    .logo-float{display: none;}
    .btn-burg-menu{display: none;}
    .nav_right{
        float: right;
        a{
            color: #fff; font-weight: 700; @include transition(0.2s all); position: relative; overflow: hidden; display: inline-block; font-size: 15px;
            /*&:before{content: ""; width: 100%; height: 2px; background: $pink; position: absolute; left: 0; top: 50%; margin-top: -2px; @include transform(translate3d(-100%,0,0)); @include transition(0.3s all);}*/
            &:hover, &.active{
                color: $pink;
                /*&:before{@include transform(translate3d(100%,0,0));}*/
            }
        }
        .nav_header, .nav_lang, .nav_sosmed, .nav_sosmed_float{float: left; position: relative;}
        .nav_header{
            li{float: left; height: 70px; line-height: 70px; padding: 0 21px;}
        }
        .nav_lang{
            line-height: 78px; padding: 0 30px 0 20px; height: 70px; position: relative;
            &:before, &:after{content: ""; position: absolute; top: 20px; width: 1px; height: 30px; background: #fff;}
            &:before{left: 0;}
            &:after{right: 0;}
            > a{position: static;}
            .arrow-bottom{position: absolute; top: 50%; right: 11px; @include transform(translateY(-50%)); width: 10px; height: 5px; background: url('../images/material/arrow-bottom.png') no-repeat left center; background-size: 200% 100%;}
            .drop_lang{
                position: absolute; top: 100%; left: 0; right: 0; line-height: 0; text-align: center; background: rgba(255,255,255,0.6); display: none;
                a{padding: 3px 0;}
                li{
                    &:first-child{
                        a{padding-top: 5px;}
                    }
                    &:last-child{
                        a{padding-bottom: 5px;}
                    }
                }
            }
        }
        .nav_sosmed, .nav_sosmed_float{
            padding-top: 23px; padding-left: 5px;
            a{
                float: left; padding-left: 10px; height: 22px; overflow: hidden;
                img{position: relative; top: 0; @include transition(0.2s top); max-width: 100%;}
                &:hover{
                    img{top: -100%;}
                }
            }
        }
        .nav_sosmed_float{display: none;}
    }
    &.float{
        border-bottom: 1px solid #eaeaea; background: #fff;
        .logo{display: none;}
        .logo-float{display: block;}
        a{color: #333333;
            &:hover, &.active{color: #f0001f;}
        }
        .nav_lang{
            &:before, &:after{background: #e5e5e5;}
            .arrow-bottom{background-position: right center;}
        }
        .nav_sosmed{display: none;}
        .nav_sosmed_float{display: block;}
    }
}
.homepage .shadow_top_header{display: none;}
.shadow_top{
    position: absolute; top: 0; left: 0; right: 0; height: 417px; z-index: 999;
    background: transparent;
    background: -moz-linear-gradient(top, rgba(0,0,0,0.5) 0%, transparent 100%);
    background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(0,0,0,0.5)), color-stop(100%, transparent));
    background: -webkit-linear-gradient(top, rgba(0,0,0,0.5) 0%, transparent 100%);
    background: -o-linear-gradient(top, rgba(0,0,0,0.5) 0%, transparent 100%);
    background: -ms-linear-gradient(top, rgba(0,0,0,0.5) 0%, transparent 100%);
    background: linear-gradient(to bottom, rgba(0,0,0,0.5) 0%, transparent 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#000000', endColorstr='#ffffff', GradientType=0 );
    /*background: rgba(0,0,0,1);
    background: -moz-linear-gradient(top, rgba(0,0,0,1) 0%, rgba(255,255,255,1) 100%);
    background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(0,0,0,1)), color-stop(100%, rgba(255,255,255,1)));
    background: -webkit-linear-gradient(top, rgba(0,0,0,1) 0%, rgba(255,255,255,1) 100%);
    background: -o-linear-gradient(top, rgba(0,0,0,1) 0%, rgba(255,255,255,1) 100%);
    background: -ms-linear-gradient(top, rgba(0,0,0,1) 0%, rgba(255,255,255,1) 100%);
    background: linear-gradient(to bottom, rgba(0,0,0,1) 0%, rgba(255,255,255,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#000000', endColorstr='#ffffff', GradientType=0 );*/
}

/* middle
----------------------------------------------------------------------------------------------*/
.all-content{@include fullfloat;}
.border-white{position: absolute; top: 70px; left: 50px; right: 50px; bottom: 0; border: 6px solid rgba(255,255,255,0.5); z-index: 1000;}
.slidehome{
    position: relative; opacity: 0;
    .sliderhome{
        .slick-prev, .slick-next{
            width: 40px; height: 77px; background-image: url('../images/material/arrow-slide-lg.png'); background-repeat: no-repeat; z-index: 1001; @include transition(0.3s all);
            &:before{display: none;}
        }
        .slick-next{
            right: 105px; background-position: right center;
            &:hover{right: 95px;}
        }
        .slick-prev{
            left: 105px; background-position: left center;
            &:hover{left: 95px;}
        }
    }
    .list-slide{
        height: 100vh;
        figure{
            height: 100%;
            img{min-height: 100%; min-width: 100%; max-width: none; @include transform(translateX(-50%)); position: relative; left: 50%;}
        }
        .desc{
            position: absolute; top: 50%;left: 50%; @include transform(translate(-50%, -50%)); margin: auto; font-size: 42px; line-height: 48px; color: #ffffff; font-family: "Trirong"; font-weight: 300; z-index: 1002; text-align: center; width: 820px; max-width: 100%;
            span{font-weight: 500;}
            .logo-lg{
                margin-bottom: 30px; text-align: center;
                img{display: inline-block;}
            }
        }
        .bg-shadow{background: url('../images/material/bg-shadow-middle.png') no-repeat center; position: absolute; top: 0; left: 0; right: 0; bottom: 0; background-position-y: 0;}
        .bg-shadow-top{@extend .shadow_top;}
    }
    .bg-shbottom{position: absolute; bottom: 0; left: 0; right: 0; background: url("../images/material/bg-shadow-bottom.png") no-repeat bottom center; height: 220px;}    
    .sc-bottom{
        position: absolute; bottom: 33px; left: 0; right: 0; margin: auto; width: 82px; font-size: 12px; color: #f28e98; text-align: center; z-index: 1001; cursor: pointer;
        span{display: block; margin-top: 7px;}
    }
}

.flwdev{position: absolute;}
.sec-content{
    position: relative; @include boxSizing(border-box); min-height: 100vh; overflow: hidden;
    *{@include boxSizing(border-box);}
    .desc-sechome{
        position: absolute; top: 50%; @include transform(translateY(-50%)); max-width: 100%; z-index: 1; padding-left: 35px;
        h2{
            font-weight: 400; margin-bottom: 55px; position: relative; line-height: 44px;
            span{font-weight: 500;}
            .line-v{width: 6px; background: $pink; position: absolute; left: -35px; top: 3px; bottom: 0;}
        }
        .quotes{font-style: italic; color: #4a4343; font-size: 24px; line-height: 30px; font-weight: 300; margin-bottom: 80px;}
    }
}

.sec-aboutus{
    position: relative;
    .trigger-about{position: absolute; top: 50%;}
    .trigger-about2{position: absolute; top: 50%;}
    .desc-sechome{
        left: 45.57%; width: 670px;
    }
    .flwdev{
        &.flw-01{
            top: 5%; right: 0; margin-top: 200px;
        }
    }
    .rings{position: absolute; top: 0; right: 19.19%; margin-top: 30%;}
}
.sec-maps{
    .mapshome{width: 100%; height: 520px;}
}

.sec-contact{
    position: relative;
    .trigger-contact{position: absolute; top: 50%;}
    .desc-sechome{width: 600px; left: 19.53%;}
    figure{float: right;}
    .flwdev{
        &.flw-02{top: 0; left: 0; margin-top: 18.05%;}
    }
}

.sec-wedding-service{
    position: relative; min-height: 100vh; padding-top: 220px; padding-bottom: 342px; @include boxSizing(border-box); overflow: hidden;
    .bg-weddingservice{position: absolute; top: 0; left: 0; right: 0; bottom: 0; background: url('../images/material/bg-home-01.jpg') no-repeat center; background-size: cover; z-index: -10;}
    .bg-shadow-top{position: absolute; top: 0; left: 0; right: 0; height: 365px; background: url('../images/material/bg-shadow-top-white.png') no-repeat center; z-index: -9;}
    .border-white{position: absolute; top: 306px; left: 50px; right: 50px; bottom: 258px; border: 6px solid rgba(255,255,255,0.5); z-index: -8;}
    .trigger-service{position: absolute; top: 30%;}
    .flwdev{
        z-index: -1;
        &.flw-03{top: 0; right: 0; margin-top: 0;}
        &.flw-04{top: 0; left: 0; margin-top: 50%;}
    }
    .content-wedding{
        z-index: 1;
        .title-content{font-family: "Trirong";}
        .box-weddingservice{
            margin-top: 62px; @include boxSizing(border-box);
            *{@include boxSizing(border-box);}
            .list-wedding{
                margin: 0 2.98%; width: 47.01%; float: left; background: #fff; padding: 30px 76px 57px; font-size: 14px; line-height: 24px;
                &:nth-child(2n){margin-right: 0;}
                &:nth-child(2n + 1){margin-left: 0;}
                .title-thumb-wedding{
                    position: relative; padding-left: 57px; margin: 30px 0;
                    h2{ font-size: 38px; line-height: 40px; height: 80px; overflow: hidden;}
                    .line-v{position: absolute; bottom: 0; left: 29px; width: 6px; height: 140px; background: $pink;}
                }
                p{
                    margin-bottom: 45px;font-size: 14px; line-height: 24px;
                    span{font-weight: 700;}
                }
            }
        }

        .box-weddingserviceone{
            @include boxSizing(border-box); background: #fff; @include fullinline; padding: 65px 70px;
            *{@include boxSizing(border-box);}
            .title-thumb-wedding{
                position: relative; margin: 15px 0 25px;
                h2{ font-size: 38px; line-height: 40px; height: 80px; overflow: hidden;}
                .line-v{position: absolute; bottom: 0; left: -30px; width: 6px; height: 140px; background: $pink;}
            }
            .left{float: left; width: 55%;}
            .right{right: right; width: 43%; padding-top: 115px; padding-left: 40px;}
        }
    }
}
.sec-ourvenue{
    background: #fafafa; position: relative; padding: 125px 0 120px;@include boxSizing(border-box); overflow: hidden;
    .trigger-ourvenue{position: absolute; top: 50%;}
    .wrapper{width: 1600px;}
    .inner-ourvenue{
        position: relative;
        .slide-venue{
            width: 1185px; max-width: 100%;
            .slidervenue{
                .slick-dots{
                    width: auto; bottom: -35px;
                    li{
                        width: 80px; height: 10px;
                        button{
                            width: 100%; padding: 0; height: 4px; background: #cccccc; position: relative;
                            &:before{position: absolute; top: 0; left: 0; bottom: 0; background: $pink; content: ""; padding: 0; width: 0; height: auto; @include transition(0.3s all);}
                        }
                        &.slick-active{
                            button{background: $pink;}
                        }
                        &:hover{
                            button{
                                &:before{width: 100%;}
                            }
                        }
                    }
                }
            }
        }
        .desc-venue{
            position: absolute; top: 60px; right: 0; width: 615px; max-width: 100%;background: #ffffff; @include boxSizing(border-box); padding: 82px 77px 52px; font-size: 16px;
            h2{
                margin-bottom: 30px; position: relative; font-family: "Trirong";
                .line-v{position: absolute; bottom: 0; left: -28px; height: 160px; width: 6px; background: $pink;}
            }
            p{line-height: 24px;}
            .vendor-partner{
                margin-top: 40px; @include fullfloat;
                h5{font-family: "Trirong"; margin-bottom: 10px; font-weight: 500;}
                .venue-top{margin-bottom: 38px;}
                .box-vendor{
                    width: 100%;
                    .list-vendor{
                        width: 30%; margin: 0 5% 10px; float: left; text-align: center;
                        &:nth-child(3n + 1){margin-left: 0;}
                        &:nth-child(3n + 2){margin-left: 0; margin-right: 0;}
                        &:nth-child(3n){margin-right: 0;}
                    }
                }
            }
        }
    }
}
.our-address{
    position: relative;
    .trigger-ouraddress{position: absolute; top: 30%;}
    .box-ouraddress{
        width: 1600px; margin: auto; background: #fff; position: relative; top: -106px;
        .inner-ouraddress{
            padding-top: 54px;
            .left{
                width: 370px; max-width: 100%; padding-left: 21px; @include boxSizing(border-box); color: #4a4343; font-size: 16px; line-height: 20px;
                h1{
                    position: relative; margin-bottom: 20px; line-height: 60px;
                    .line-v{position: absolute; bottom: 0; left: -27px; width: 6px; height: 160px; background: $pink;}
                }
                h4{color: #333; line-height: 24px; margin-bottom: 15px;}
            }
            .right{
                float: right; width: 670px;
                .career-icon{width: 128px; float: left; max-width: 100%;}
                .content-careerhome{
                    width: 500px; float: right; max-width: 100%; font-size: 16px; line-height: 22px;
                    h3{margin-bottom: 15px; line-height: 40px;}
                    a{
                        color: $pink; text-decoration: underline;
                        &:hover{color: #333;}
                    }
                    .box-sosmed-lg{
                        margin-top: 30px;
                        a{color: #4a4343; text-decoration: none;}
                    }
                }
            }
        }
    }
}

.box-call{
    margin-top: 20px; color: $pink; font-size: 16px; line-height: 26px;
    a{
        display: block;color: $pink; @include transition(0.2s all);
        .icwp{position: relative; left: 0;@include transition(0.1s all);}
        &:hover{
            color: #333;
            .icwp{left: 2px;}
        }
    }
}

.box-sosmed-lg{
    a{
        float: left; text-align: center; font-size: 14px; color: #4a4343;text-decoration: none; width: 25%;
        figure{
            @include borderRadius(100%); border: 3px solid #999999; width: 69px; height: 69px; @include boxSizing(border-box); position: relative; margin-bottom: 10px; display: inline-block; @include transition(0.2s all);
            i.icwp{position: absolute; top: 50%; left: 50%; @include transform(translate(-50%,-50%));}
            &:hover{border-color: $pink;}
        }
        span{display: block; text-align: center;}
    }
}

.banner{
    position: relative;
    figure{overflow: hidden;}
}
.aboutbox{
    position: relative; text-align: center; padding-top: 90px; padding-bottom: 90px;
    .rings{position: absolute; top: 0; left: 2%; margin-top: 330px;}
    .btn-inqury{position: absolute; top: 132px; right: 20px;}
    h1{margin-bottom: 105px;}
    .innerabout{width: 770px; margin: 0 auto; max-width: 100%;}
    .subtitle{margin-bottom: 75px;}
    .lineh{width: 140px; height: 2px; background: $pink; display: inline-block; margin: 15px 0 60px;}
    .flw-left-service{position: absolute; top: 0; left: 0; margin-top: 400px;}
    .flw-right-service{position: absolute; top: 0; right: 0; margin-top: 45%;}
    .flw-left-gallery{position: absolute; top: 0; left: 0; margin-top: 40%;}
    .flw-right-gallery{position: absolute; top: 0; right: 0; margin-top: 290px;}
    .desc{
        font-size: 14px; color: #4a4343; line-height: 24px;
        p{line-height: 24px;}
    }
    .box-three-ikk{
        @include boxSizing(border-box);margin: 90px auto 0; width: 1070px; max-width: 100%;
        .list{
            @include boxSizing(border-box); width: 25.23%; float: left; position: relative; height: 75px; font-family: "Trirong"; margin-right: 12.14%;
            &:first-child{
                .f-right{padding-left: 70px;}
            }
            &:last-child{margin-right: 0;}
            .f-lg{font-weight: 800; font-size: 80px; color: #e1cba6; position: absolute; top: 0; left: 0;}
            .f-right{
                color: #111;font-size: 28px; font-weight: 800; text-align: left; padding-left: 100px; position: absolute; top: 50%; @include transform(translateY(-50%));
                span{font-size: 18px; font-style: italic; font-weight: 400;}
            }
        }
    }
    .about-quotes{margin-top: 132px;}
}
.about-quotes{
    @include fullfloat;
    .text-quotes{font-family: "Trirong"; font-style: italic; font-size: 30px; color: #111; font-weight: 300; line-height: 48px; margin-top: 56px;}
    .auth-text{font-size: 14px; font-weight: 700; color: #e1cba6; text-transform: uppercase; letter-spacing: 2px; margin-top: 60px; display: block;}
}
.title-content{
    margin-bottom: 50px;
    h1{margin-bottom: 20px;}
    h4{
        font-size: 30px; color: #111; font-weight: 500; margin-bottom: 20px; line-height: 48px;
        &.caps-detail{margin-top: 115px; margin-bottom: 50px;}
    }
    h1.tmd{font-weight: 500;}
    i{font-style: normal;}
    span{font-size: 14px; color: #e1cba6; text-transform: uppercase; display: block; margin-bottom: 10px; letter-spacing: 3px;}
}
.about-story{
    text-align: center; padding-bottom: 100px; overflow: hidden;
    .flw-right{position: absolute; top: -196px; right: 0; margin-top: 170px;}
    .box-slide-story{
        float: left; position: relative; width: 445px; max-height: 600px; @include boxSizing(border-box);
        .line-v{position: absolute; top: 0; bottom: 0; left: 370px; width: 1px; background: #999;}
        .navsliderstory{
            height: 100%;
            .slick-list{max-height: 500px !important;}
            .slick-prev, .slick-next{
                @include transition(0.3s all);
                &:before{display: none;}
            }
            .slick-prev{
                position: absolute; top: -20px; left: 363px; background: url('../images/material/arrow-slide-vertical.png') no-repeat left center; width: 15px; height: 30px;
                &:hover{background-position: top left;}
            }
            .slick-next{
                position: absolute; top: auto; bottom: -50px; left: 363px; background: url('../images/material/arrow-slide-vertical.png') no-repeat right center; width: 15px; height: 30px;
                &:hover{background-position: bottom right;}
            }
            .list-story{
                padding-right: 75px;
                .a-list{
                    position: relative; text-align: right; font-size: 16px; font-weight: 800; font-family: "Trirong";color: #999; line-height: 24px; padding-bottom: 50px; display: block;  padding-right: 71px;
                    &:before{content: ""; position: absolute; top: 25%; right: -4px; @include transform(translateY(-50%)); width: 7px; height: 7px; background: #999; @include transition(0.3s all);}
                    h5{font-weight: 800;}
                    .a-active{
                        display: none;
                        h1{color: #f9c7cb; font-weight: 800; line-height: 60px;}
                        h3{color: #e1cba6; font-weight: 400; line-height: 36px;}
                    }
                }
                &.slick-current{
                    .a-list{
                        &:before{width: 90px; background: #f9c7cb; right: -62px;}
                        h5{display: none;}
                        .a-active{display: block;}
                    }
                    .desc{display: block;}
                    &:hover{
                        .a-list:before{width: 90px;}
                    }
                }
                &:hover{
                    .a-list:before{width: 30px;}
                }
            }
        }
    }
    .box-slider-story-desc{
        float: right; width: 700px;
        .navstoryres{display: none;}
        .desc{
            text-align: left; padding: 90px 0 10px; font-size: 20px; font-family: "Trirong"; line-height: 34px;
            p{line-height: 34px; margin-bottom: 40px;}
            .title-story{
                display: none;
                h3{color: #e1cba6;font-weight: 400;line-height: 36px;}
                h1{color: #f9c7cb;font-weight: 800;line-height: 60px;}
            }
        }
    }
    .box-story{
        position: relative; padding: 38px 0;
        .line-v{position: absolute; top: 0; bottom: 0; left: 370px; width: 1px; background: #999;}
        .prev-story{position: absolute; top: -20px; left: 363px; background: url('../images/material/arrow-slide-vertical.png') no-repeat left center; width: 15px; height: 8px;}
        .next-story{position: absolute; bottom: -20px; left: 363px; background: url('../images/material/arrow-slide-vertical.png') no-repeat right center; width: 15px; height: 8px;}
        .list-story{
            width: 370px; @include boxSizing(border-box);
            *{@include boxSizing(border-box);}
            .a-list{
                position: relative; text-align: right; font-size: 16px; font-weight: 800; font-family: "Trirong";color: #999; line-height: 24px; margin-bottom: 50px; display: block;  padding-right: 71px;
                &:before{content: ""; position: absolute; top: 50%; right: -4px; @include transform(translateY(-50%)); width: 7px; height: 7px; background: #999; @include transition(0.3s all);}
                h5{font-weight: 800;}
                .a-active{
                    display: none;
                    h1{color: #f9c7cb; font-weight: 800; line-height: 60px;}
                    h3{color: #e1cba6; font-weight: 400; line-height: 36px;}
                }
            }
            .desc{
                position: absolute; right: 0; width: 700px; top: 0; text-align: left; padding: 90px 0; font-size: 20px; font-family: "Trirong"; line-height: 34px; display: none;
                p{line-height: 34px; margin-bottom: 40px;}
            }
            &.active{
                .a-list{
                    &:before{width: 90px; background: #f9c7cb; right: -62px;}
                    h5{display: none;}
                    .a-active{display: block;}
                }
                .desc{display: block;}
            }
        }
    }
}
.philosophy{
    text-align: center; padding-bottom: 200px;
    .quotes-philosophy{
        h1{font-weight: 800; letter-spacing: 15px; font-size: 50px; margin-bottom: 30px;}
        span{font-size: 16px; color: #4a4343; font-style: italic; display: block; margin-bottom: 35px;}
        h2{font-size: 35px; color: $pink; font-style: italic; font-weight: 500;}
    }
    .box-two.visi{
        @include boxSizing(border-box);margin-top: 110px;text-align: left; padding: 0 70px;
        *{@include boxSizing(border-box);}
        .list{
            width: 430px; float: left;
            &.fr{float: right;}
            h5{font-family: "Lato"; font-weight: 700; letter-spacing: 2px; margin-bottom: 60px; color: #000; text-align: center;}
            ul{
                li{
                    position: relative; padding-left: 25px; color: #4a4343;font-size: 14px; line-height: 24px; margin-bottom: 20px;
                    &:before{content: ""; position: absolute; top: 8px; left: 0; width: 7px; height: 7px; background: #000;}
                }
            }
            .mphilosophy{
                text-align: center; font-size: 14px; line-height: 24px;
                h5{font-size: 18px; font-weight: 300; font-family: "Lato"; margin-bottom: 15px; letter-spacing: 0;}
                h4{font-size: 23px; font-weight: 400; font-family: "Lato"; margin-bottom: 40px;}
            }
            &.misi{
                font-size: 14px; line-height: 24px;
                p{line-height: 24px;}
            }
        }
    }
}
.our-team{
    text-align: center; position: relative; padding-bottom: 152px;
    .flw-left{position: absolute; top: 0; left: 0; margin-top: 30px;}
    .fig-team{margin-bottom: 70px;}
    .title-content{
        line-height: 48px; font-size: 30px;
        h4{width: 740px; max-width: 100%; margin: 0 auto 30px;}
    }
    p.filsof{width: 570px; margin: auto; font-size: 14px; line-height: 24px; color: #4a4343; margin-bottom: 140px; max-width: 100%;}
    .about-quotes{
        .text-quotes{width: 680px; max-width: 100%; margin-left: auto; margin-right: auto;}
    }
}

.box-service{
    position: relative; @include boxSizing(border-box); @include fullfloat;
    *{@include boxSizing(border-box);}
    .line-v{position: absolute; top: 0; bottom: 0; width: 6px; background: #f28e98; left: 50%; margin-left: -3px;}
    .list-service{
        position: relative;margin-bottom: 60px;@include fullfloat;
        .service-desc{
            text-align: right; font-size: 14px; line-height: 24px; color: #4a4343; width: 50%; padding-right: 73px; position: absolute; top: 50%; left: 0; @include transform(translateY(-50%));
            h1{font-size: 80px; color: #e1cba6; font-weight: 800; margin-bottom: 20px; line-height: 80px;}
            h3{font-size: 38px; font-weight: 300; color: #111111; margin-bottom: 30px; line-height: 38px;}
        }
        .service-img{
            width: 50%; float: right; padding-left: 73px;
            figure{float: left;}
        }
        &:nth-child(odd){
            .service-desc{left: auto; right: 0; padding-left: 73px; padding-right: 0; text-align: left;}
            .service-img{
                float: left; padding-left: 0; padding-right: 73px;
                figure{float: right;}
            }
        }
    }
}

.explore-venue{
    @include boxSizing(border-box); @include fullfloat; padding-bottom: 180px; position: relative;
    .flw-left-venue{position: absolute; top: 0; left: 0; margin-top: 0;}
    .title-content{text-align: center;}
}
.box-exvenue{
    @include boxSizing(border-box); @include fullfloat; text-align: center;
    *{@include boxSizing(border-box);}
    .list-venue{
        margin-right: 1.8%; width: 31.96%; float: none; position: relative; overflow: hidden; margin-bottom: 30px; display: inline-block; text-align: left;
        &:nth-child(3n){margin-right: 0;}
        .box-desc{
            position: absolute; top: 38px; left: 38px; right: 38px; bottom: 38px; background: #fff; padding: 45px 28px; overflow: hidden;
            @include transform(scale(1.2)); opacity: 0; @include transition(0.3s all); visibility: hidden;
            .desc{
                overflow: hidden; max-height: 100%;
                h4{font-size: 24px; line-height: 30px; color: #000; font-weight: 400;}
            }
            .link-exvenue{position: absolute; bottom: 35px; right: 25px;}
            .link-line{
                &:before{top: 8px;}
            }
        }
        &:hover{
            .box-desc{opacity: 1;  visibility: visible; @include transform(scale(1));}
        }
    }
}

.coming-soon{
    @include boxSizing(border-box); height: 100vh; min-height: 850px;
    *{@include boxSizing(border-box);}
    .left{
        width: 40.36%; position: absolute;top: 0; bottom: 0; padding: 38px 72px; background: #ffffff;
        .content-cs{
            text-align: center;position: absolute; z-index: 10; width: auto; max-width: 100%; top: 44%; left: 72px; right: 72px; @include transform(translateY(-50%));
            h1{margin-bottom: 60px;}
            h5{font-size: 20px; line-height: 40px; color: #000; margin-bottom: 80px;}
           .flw-right-cs{position: absolute; top: 82%; left: -72px;}
        }
    }
    .right{
        width: 59.63%; float: right;
        .slidercs{
            .list-slide{
                height: 100vh;  min-height: 850px; position: relative; background-repeat: no-repeat; background-size: cover; background-position: center;
                figure{height: 100%; background-size: cover; background-position: center;}
                .desc{
                    position: absolute; bottom: 0; left: 95px; right: 95px; background: #ffffff; padding: 30px 85px; font-size: 16px; line-height: 24px; color: #666666;
                    h1{
                        font-size: 45px; position: relative; line-height: 45px; margin-bottom: 30px; color: #000;
                        .line-v{position: absolute; bottom: 0; left: -24px; width: 6px; height: 97px; background: $pink;}
                    }
                    p{line-height: 24px;}
                }
            }
        }
    }
    .box-partner-cs{
        position: absolute; bottom: 30px; left: 50px; width: 36.5%;
        .list-partner{width: 20%; padding: 0 10px; float: left; margin-bottom: 10px;text-align: center;}
    }
}

.box-gallery{
    @include boxSizing(border-box); margin-bottom: 30px; @include fullfloat; text-align: center;
    *{@include boxSizing(border-box);}
    .list-gallery{
        margin-right: 1.8%; width: 31.96%; margin-bottom: 30px; position: relative; display: inline-block; text-align: left;
        &:nth-child(3n){margin-right: 0;}
        img{width: 100%;}
        .thumb-vids{
            position: absolute; top: 0; right: 0; width: 50px; height: 50px; background: #eb4e5e; text-align: center; padding-top: 17px;
        }
    }
}
.box-loadmore{@include fullfloat;}

.detailsection{
    position: relative; text-align: center; padding-top: 90px; padding-bottom: 105px;
    .btn-inqury{position: absolute; top: 132px; right: 20px;}
    .flw-love-detail{position: absolute; margin-top: 28%; left: 0; top: 0;}
    .flw-right-gallery{position: absolute; margin-top: 76%; right: 0; top: 0;}
    .wrapper{
        width: 770px; max-width: 100%;
    }
}
.desc-detvenue{
    font-size: 16px; line-height: 24px; font-family: "Lato"; font-weight: 400; color: #000000;
}
.box-detail-venue{
    @include fullfloat; margin-top: 70px; margin-bottom: 100px;
    .left{
        float: left; width: 64.93%; max-width: 100%;
        figure{max-width: 100%;}
    }
    .right{
        float: right; max-width: 100%; width: 29.87%; text-align: left; padding-top: 20px;
        h4{font-size: 16px; font-weight: 700; letter-spacing: 1.6px; margin-bottom: 15px;}
        span{font-size: 16px; line-height: 20px; color: #333333; display: block; margin-bottom: 20px;}
    }
}
.box-partner{
    @include fullfloat; @include boxSizing(border-box);
    *{@include boxSizing(border-box);}
    .list-partner{width: 20%; padding: 0 10px; float: left; margin-bottom: 10px;}
}
.box-gallery-detail{
    position: relative; text-align: center;
    hr{@include fullfloat; background: #e7e7e7; height: 2px; border: none;}
    .box-slide-gallery{
        margin-bottom: 75px;
        .slick-prev, .slick-next{
            width: 17px; height: 27px; background-image: url('../images/material/arrow-slide-gallery-detail.png'); background-repeat: no-repeat;
            &:before{display: none;}
        }
        .slick-prev{background-position: left center; left: -54px;}
        .slick-next{background-position: right center; right: -54px;}
        .slick-dots{
            text-align: left;
            li{
                width: auto; height: auto; margin-right: 5px; margin-left: 0;
                button{
                    width: 45px; height: 4px; background: #cccccc; padding: 0;
                    &:before{display: none;}
                }
                &.slick-active, &:hover{
                    button{background: $pink;}
                }
            }
        }
    }
    .other-venue{@include fullfloat;}
}
.venue-location{
    text-align: left; position: relative; @include fullfloat; margin-bottom: 130px; margin-top: 110px;
    .ven-left{
        width: 870px; max-width: 100%; float: left;
        .mapscontact{width: 100%; height: 536px;}
    }
    .ven-right{
        position: absolute; top: 98px; background: #ffffff; width: 470px; right: 0; @include boxSizing(border-box); padding: 65px 70px 42px; font-size: 16px; line-height: 20px; color: #4a4343;
        h2{
            position: relative; margin-bottom: 35px; color: #000; line-height: 45px;
            .line-v{width: 6px; height: 160px; position: absolute; bottom: 0; left: -30px; background: $pink;}
        }
        h4{font-size: 24px; line-height: 30px; color: #333333; font-weight: 400; margin-bottom: 10px;}
        p{line-height: 20px; margin-bottom: 35px;}
        .link-derection{font-style: 16px; color: $pink; font-weight: 700;}
    }
}

.other-venue-section{
    position: relative; text-align: center;padding-bottom: 140px;
    .flw-left-other{position: absolute; top: -130px; left: 0;}
}

.box-contact-right{
    position: fixed; top: 0; left: 0; right: 0; min-height: 100%; background: rgba(0,0,0,0.2);z-index: 10000; overflow-y: auto; @include boxSizing(border-box);
    display: none;
    *{@include boxSizing(border-box);}
    .inner{
        position: absolute; top: 0; right: 0; min-height: 100%; background: #fff; width: 775px; max-width: 100%; @include boxSizing(border-box); padding: 80px 56px 33px;
        h3{font-size: 35px; color: #111; padding-left: 42px; margin-bottom: 48px;}
        p{font-size: 16px; line-height: 24px; color: #000000; margin-bottom: 30px;}
        .line-v{width: 6px; height: 112px; background: $pink; top: 0; left: 60px; position: absolute;}
        .btn-close{
            position: absolute; top: 0; right: 0; width: 50px; height: 50px; background: $pink; text-align: center; line-height: 50px; cursor: pointer; @include transition(0.3s all);
            &:hover{background: #f0001f;}
        }
    }
}
.ui-datepicker table{font-size: 14px;}
.ui-widget{font-size: 16px;}
.box-inquiry{
    position: fixed; bottom: 30px; right: 30px;z-index: 9000;
    .btn-oval{padding: 0 30px 0 30px;}
    .ic-inquiry{vertical-align: middle; position: relative; top: -3px; margin-right: 8px;}
}
.box-form{
    @include boxSizing(border-box); @include fullfloat;
    *{@include boxSizing(border-box);}
    input[type="text"], input[type="email"], textarea{
        border: none; border-bottom: 1px solid #c9b596; color: #eb4e5e; font-size: 24px; font-family: "Trirong"; width: 100%; padding-left: 5px; padding-right: 5px; width: 100%; @include transition(0.3s all); background: transparent;
        &:focus, &:active{border-color: #eb4e5e;}
    }
    @include placeholder{color: #cccccc;}
    textarea{resize: none; font-size: 18px; height: 90px; line-height: 24px;}
    .row{
        margin-bottom: 20px; @include fullfloat;
        label{font-size: 16px; line-height: 24px; color: #111; display: block;}
    }
    .tdate{
        input[type="text"]{padding-right: 60px;}
    }
    .captcha{float: left; margin-bottom: 10px;}
    .lfaq{
        font-size: 16px; line-height: 24px; color: #999999;
        a{color: $pink;}
    }
}
.box-filter{
    @include fullfloat; border: 1px solid #cccccc; margin-bottom: 100px;
    select{float: left;border: none; font-size: 18px; color: #000000; font-family: "Trirong"; height: 98px; padding: 0 47px; background: url('../images/material/bg-combobox.png') no-repeat right center; width: 100%;}
    .list-filter-gallery{
        float: left; border-right: 1px solid #e5e1de;
        &:last-child{border-right: none;}
        &.search-filter{text-align: center; padding: 25px 0 0; width: 280px;}
        &.svpartner{width: 240px;}
        &.svenue{width: 164px;}
        &.swtheme{width: 249px;}
        &.sphoto{width: 232px;}
    }
}

.faq-content{
    position: relative; text-align: center; padding-top: 90px; padding-bottom: 105px;
    .flw-left-faq{position: absolute; margin-top: 40%; left: 0; top: 0;}
    .btn-inqury{position: absolute; top: 132px; right: 20px;}
    .wrapper{width: 770px; max-width: 100%;}
}
.box-faq{
    text-align: left; color: #000000; font-size: 16px; line-height: 24px; margin-top: 110px; @include fullfloat;
    .list-faq{
        @include fullfloat; padding: 24px 0; position: relative;
        &:before, &:after{content: ""; width: 100%; height: 1px; background: #ccc; position: absolute; bottom: 0;}
        &:before{background: #ccc; width: 100%; left: 0;}
        &:after{background: #eb4e5e; width: 0; @include transition(0.3s all ease-out); left: 50%;}
        .qst{font-size: 18px; line-height: 28px; font-weight: 600; font-family: "Trirong"; color: #111; cursor: pointer; @include transition(0.3s all);}
        .answr{padding-top: 20px; display: none;}
        &.active, &:hover{
            .qst{color: #eb4e5e;}
            &:after{width: 100%; left: 0;}
        }
        &.active{
            .answr{display: block;}
        }
    }
}
.contact-content{
    position: relative; text-align: center;
    .flw-contact-right{position: absolute; right: 0; margin-top: 10%; top: 0;}
    .flw-contact-left{position: absolute; left: 0; margin-top: 70%; top: 0;}
    .wrapper{width: 770px;}
}
.box-contact{text-align: left;}
.box-follow-contact{
    margin-top: 130px; @include fullfloat; position: relative;
    .box-sosmed-lg{
        text-align: center;
        a{width: 140px; float: none; display: inline-block;}
    }
}

.banner-thankyou{
    position: relative;
    @include fullfloat; min-height: 90vh;
    .desc{
        position: absolute; top: 75%; left: 0; right: 0; width: 570px; margin: 0 auto; text-align: center; @include transform(translateY(-50%)); max-width: 100%;
        h1{font-size: 90px; color: #596c59; font-family: "Lato"; font-weight: 100; margin-bottom: 35px;}
        h5{font-size: 20px; line-height: 31px; font-family: "Lato"; font-weight: 400; color: #8f836d; margin-bottom: 30px;}

    }
}


.popup-gallery{
    position: fixed; top: 0; left: 0; right: 0; bottom: 0;z-index: 10000; background: #fff; opacity: 0; visibility: hidden;
    .close-pop-gallery{
        position: absolute; top: 21px; right: 0; @include boxSizing(border-box); height: 50px; line-height: 50px; color: #fff; font-size: 16px; font-family: "Trirong"; font-weight: 500; background: #f08e98; z-index: 1; padding: 0 28px; cursor: pointer;
        &:before{content: ""; position: absolute; top: 0; bottom: 0;left: 0; width: 0; background: #f0001f; @include transition(0.2s all); z-index: -1;}
        .ic-close{margin-left: 20px;}
        &:hover{
            &:before{width: 100%;}
        }
    }
    .img-large{
        .list-slide{
            /*min-height: 100vh;*/
            height: 100vh;
            iframe{height: 100vh; width: 100%;}
            figure{
                img{height: 100%; min-width: 100%; max-width: none;}
            }
            .caps-large{
                position: absolute; bottom: 0; left: 0; right: 0; margin: 0 auto; text-align: center; width: 1075px;background: rgba(255,255,255,0.4) url(../images/material/bg_top.png) repeat-x top center;max-width: 100%;
                padding: 30px 20px 150px; @include boxSizing(border-box);font-size: 20px;line-height: 18px;font-style: italic;font-weight: 600;color: #000;
                &:after {
                    content:""; height: 2px; width: 100px; background:#f08e98; position: absolute; left: 0; right: 0; margin: auto; bottom: 132px;
                }
            }
        }
    }
    .box-thumb-slider{
        position: absolute; bottom: 0; left: 0; right: 0; width: 1075px; margin: 0 auto; padding: 30px 0;background: rgba(255,255,255,0.1);
        .wrapper {width: 900px;}
        .caption-slide{text-align: center; font-size: 20px; line-height: 25px; font-style: italic; font-weight: 600; color: #333; margin-bottom: 55px;}
        .slick-prev, .slick-next{
            width: 18px; height: 27px; background-image: url('../images/material/arrow-slide-gallery-detail.png'); background-repeat: no-repeat; @include transition(0.2s all);
            &:before{display: none;}
        }
        .slick-prev{
            background-position: left center; left: -52px;
            &:hover{left: -56px;}
        }
        .slick-next{
            background-position: right center; right: -52px;
            &:hover{right: -56px;}
        }
        .list-slide{
            text-align: center; margin: 0 9px;
            figure{
                text-align: center; position: relative; cursor: pointer; display: inline-block; height: auto;
                &:before{content: ""; position: absolute; top: 0; left: 0; right: 0; bottom: 0; background: rgba(0,0,0,0.5); @include transition(0.2s all);}
                img{display: inline-block;}
            }
            &:hover{
                figure{
                    &:before{background: rgba(0,0,0,0.2);}
                }
            }
            &.slick-current{
                figure{
                    &:before{display: none;}
                }
            }
        }
    }
}

.v-all {
    margin: 30px 0 40px;
    .btn-oval {display: inline-block;}
}

.box-wa {
    position: fixed; right: 40px; bottom: 30px; z-index: 9000;
    a {
        display: inline-block; background: #2ad265; border-color: #2ad265; font-size: 15px; padding: 0 30px;
        i{display: inline-block; vertical-align: middle; margin-right: 10px; top: -3px;}
        &:hover {
            color: #2ad265; border-color: #2ad265;
            i {background-position: bottom center;}
        }

    }
    &.absSroll {
        animation-name: bounching;animation-duration: .3s; bottom: 100px;
    }
}

@keyframes bounching {
    0% { bottom: 95px; }
    35% { bottom: 100px; }
    65% { bottom: 95px; }
    100% { bottom: 100px; }
}
/* fancybox popup and slider
----------------------------------------------------------------------------------------------*/
/*.fancybox-lock{
    .fancybox-overlay{
        background: rgba(0,0,0,0.8);
    }
}
.fancybox-wrap{
    top: 0 !important; left: 0 !important; bottom: 0; right: 0; width: auto !important; height: auto !important;
    .fancybox-skin{
        position: absolute; top: 0; left: 0; right: 0; bottom: 0; background: #fff;
        .fancybox-outer, .fancybox-inner{position: absolute; top: 0; left: 0; right: 0; bottom: 0;}
        .fancybox-inner{width: auto !important; height: auto !important;}
        .fancybox-title-float-wrap{bottom: 250px; margin: 0;}
    }
}
#fancybox-thumbs{
    ul{
        background: #fff; padding: 110px 80px 33px !important; @include boxSizing(border-box); width: 1330px !important; height: 240px !important; left: 50% !important; @include transform(translateX(-50%));
        li{
            padding: 0 15px !important;
            a{
                width: auto !important; height: 100px !important; background: transparent;
                img{height: 100px !important; width: auto !important;}
            }
            &.active{border: none !important;}
        }
    }
    &.bottom{bottom: 0 !important;}
}*/

/* end fancybox popup and slider
----------------------------------------------------------------------------------------------*/


/* footer
----------------------------------------------------------------------------------------------*/
footer{
    text-align: center; min-height: 92px; @include boxSizing(border-box); border-top: 1px solid #f0001f;
    color: #333; font-size: 14px; @include fullfloat; line-height: 92px; text-transform: uppercase;
    a{@include transition(0.2s all); color: #333;
        &:hover{color: #f28e98;}
    }
}